import {Component, Vue} from 'vue-property-decorator';
import {SurveyType} from "../../shared/model/SurveyType";
import {Status} from "../../shared/model/Status";
import {evalApi} from "../../plugins/axios";
import {Flag} from "@/shared/model/Flag";
import {rules} from "@/shared/model/Rules";
import {BudgetPool} from "@/shared/model/BudgetPool";

@Component({
    components: {},
})
export default class SurveyTypeForm extends Vue {
    title = "SurveyType Form"
    maskText = "";
    valid = true;
    surveyType: SurveyType = new SurveyType();
    statuses = Status.values;
    cpFlags = Flag.values;
    flags = ["Y","N"];
    budgetPools = BudgetPool.values.sort();
    requiredRule = rules;
    snackbar = {
        visible: false,
        color: "success",
        text: ""
    };

    formatDate(date: String): String {
        if (!date) return "MM/DD/YYYY";
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
    }

    async submitNewSurveyType(): Promise<void> {
        const res = await evalApi.get(`/surveyType/exists/` + this.surveyType.surveyTypeId)
        if (res.data) {
            this.snackbar.color = "error"
            this.snackbar.text = "Survey Type ID '" + this.surveyType.surveyTypeId + "' already exists. No duplicates are allowed."
            this.snackbar.visible = true
            return
        }
        evalApi.post("/surveyType/create", {
            surveyTypeId: this.surveyType.surveyTypeId,
            surveyTypeDesc: this.surveyType.surveyTypeDesc,
            surveyType: this.surveyType.surveyType,
            surveyStatus: this.surveyType.surveyStatus,
            commonProjectFlag: this.surveyType.commonProjectFlag,
            expReportFlag: this.surveyType.expReportFlag,
            budgetPool: this.surveyType.budgetPool,
            nfIcfmrFlag: this.surveyType.nfIcfmrFlag,
            surveyClassification: this.surveyType.surveyClassification,
        }).then(res => {
            this.snackbar.color = "success"
            this.snackbar.text = "Survey Type '" + this.surveyType.surveyTypeId + "' has been created."
            this.snackbar.visible = true
        }).catch(error => {
            this.snackbar.color = "error"
            this.snackbar.text = "An error occurred updating the survey type: >>>> " + error
            this.snackbar.visible = true
        })
    }

    lengthRules = [
        //(v:any) => !!v || 'Required',
        (v:any) =>
            (v && v.length <= 100) ||
            "Must be less than 100 characters"
    ]

}